import Connector from './connector'

import { IConnector } from '@/models/repository/Common'
import {
  IAuthActions,
  ICoreService,
  IDealActions,
  IImportProcessActions,
  IPurposeActions,
} from './interfaces'
import AuthActions from './AuthActions'
import DealActions from './DealActions'
import ImportProcessActions from './ImportProcessActions'
import PurposeActions from './PurposeActions'

export default class CoreService implements ICoreService {
  constructor(store: any) {
    this.#connector = new Connector(store)
    this.authActions = new AuthActions(this.#connector)
    this.dealActions = new DealActions(this.#connector)
    this.importProcess = new ImportProcessActions(this.#connector)
    this.purposeActions = new PurposeActions(this.#connector)
  }
  #connector: IConnector
  authActions: IAuthActions
  dealActions: IDealActions
  importProcess: IImportProcessActions
  purposeActions: IPurposeActions
}
