import {
  GetListResponse,
  IConnector,
  IResultJSONModel,
  IResultModel,
  QueryContract,
} from '@/models/repository/Common'
import {
  DealChartData,
  DealChartExtendedData,
  DealChartScatterData,
  DealFilter,
  DealsCoordinates,
  DealsChartDataType,
  DealsQueryContract,
  GetListDealResponse,
  GetDealStatsResponse,
  Deal,
  DealFlagsUpdateContract,
  GetDealsPieChartResponse,
  RoomsPriceChartData,
} from '@/models/repository/DealContracts'
import { IDealActions } from '../interfaces'

export default class DealActions implements IDealActions {
  connector: IConnector
  constructor(connector: IConnector) {
    this.connector = connector
  }

  getList = async (
    query: QueryContract,
    filter: DealFilter
  ): Promise<IResultModel<GetListDealResponse>> => {
    try {
      const params: DealsQueryContract = {
        ...filter,
        ...query,
        page: query.page ?? 1,
        limit: query.limit ?? 20,
      }
      const response = await this.connector.conn.get<
        IResultJSONModel<GetListDealResponse>
      >(`/v1/deals`, { params })

      if (response.status !== 200) {
        return {
          value: null,
          error: new Error(`API error, status: ${response.status}`),
        }
      }
      if (!response.data.result) {
        return {
          value: null,
          error: new Error(`API returned empty response`),
        }
      }
      return {
        value: response.data.result,
        error: null,
      }
    } catch (e) {
      return {
        value: null,
        error: new Error('Network error, try again late'),
      }
    }
  }

  getDealStats = async (
    query: QueryContract,
    filter: DealFilter
  ): Promise<IResultModel<GetDealStatsResponse>> => {
    try {
      const params: DealsQueryContract = {
        ...filter,
        ...query,
        page: query.page ?? 1,
        limit: query.limit ?? 20,
      }
      const response = await this.connector.conn.get<
        IResultJSONModel<GetDealStatsResponse>
      >(`/v1/deals/stats`, { params })

      if (response.status !== 200) {
        return {
          value: null,
          error: new Error(`API error, status: ${response.status}`),
        }
      }
      if (!response.data.result) {
        return {
          value: null,
          error: new Error(`API returned empty response`),
        }
      }
      return {
        value: response.data.result,
        error: null,
      }
    } catch (e) {
      return {
        value: null,
        error: new Error('Network error, try again late'),
      }
    }
  }

  getListByCoordinates = async (
    query: QueryContract,
    filter: DealFilter,
    coordinates: DealsCoordinates
  ): Promise<IResultModel<GetListDealResponse>> => {
    try {
      const params: DealsQueryContract = {
        ...filter,
        ...query,
        lat_lb: Number(coordinates?.leftBottom?.latitude),
        lng_lb: Number(coordinates?.leftBottom?.longitude),
        lat_rt: Number(coordinates?.rightTop?.latitude),
        lng_rt: Number(coordinates?.rightTop?.longitude),
      }
      const response = await this.connector.conn.get<
        IResultJSONModel<GetListDealResponse>
      >(`/v1/deals/by-coordinates`, { params })

      if (response.status !== 200) {
        return {
          value: null,
          error: new Error(`API error, status: ${response.status}`),
        }
      }
      if (!response.data.result) {
        return {
          value: null,
          error: new Error(`API returned empty response`),
        }
      }
      return {
        value: response.data.result,
        error: null,
      }
    } catch (e) {
      return {
        value: null,
        error: new Error('Network error, try again late'),
      }
    }
  }

  getById = async (id: string): Promise<IResultModel<Deal>> => {
    try {
      const response = await this.connector.conn.get(`/v1/deals/${id}`)

      if (response.status === 200) {
        return {
          value: response.data.result,
          error: null,
        }
      } else {
        return {
          value: null,
          error: new Error('Network error, try again late'),
        }
      }
    } catch (e) {
      return {
        value: null,
        error: new Error('Network error, try again late'),
      }
    }
  }

  update = async (id: string, data: Deal): Promise<IResultModel<Deal>> => {
    try {
      const response = await this.connector.conn.put(`/v1/deals/${id}`, data)

      if (response.status === 200) {
        return {
          value: response.data.result,
          error: null,
        }
      } else {
        return {
          value: null,
          error: new Error('Network error, try again later'),
        }
      }
    } catch (e) {
      return {
        value: null,
        error: new Error('Network error, try again later'),
      }
    }
  }

  createNewDeal = async (
    id: string,
    data: { transactionsIDs: string[] }
  ): Promise<IResultModel<Deal>> => {
    try {
      const response = await this.connector.conn.post(
        `/v1/deals/${id}/from-existing`,
        data
      )

      if (response.status === 200) {
        return {
          value: response.data.result,
          error: null,
        }
      } else {
        return {
          value: null,
          error: new Error('Network error, try again later'),
        }
      }
    } catch (e) {
      return {
        value: null,
        error: new Error('Network error, try again later'),
      }
    }
  }

  updateDealFlag = async (
    id: string,
    data: DealFlagsUpdateContract
  ): Promise<IResultModel<Deal>> => {
    try {
      const response = await this.connector.conn.patch(`/v1/deals/${id}`, data)

      if (response.status === 200) {
        return {
          value: response.data.result,
          error: null,
        }
      } else {
        return {
          value: null,
          error: new Error('Network error, try again later'),
        }
      }
    } catch (e) {
      return {
        value: null,
        error: new Error('Network error, try again later'),
      }
    }
  }

  getPricesChart = async (
    filter: DealFilter
  ): Promise<IResultModel<GetListResponse<DealChartData>>> => {
    try {
      const params: DealsQueryContract = {
        ...filter,
      }
      const response = await this.connector.conn.get<
        IResultJSONModel<GetListResponse<DealChartData>>
      >(`/v1/deals/prices`, { params })

      if (response.status !== 200) {
        return {
          value: null,
          error: new Error(`API error, status: ${response.status}`),
        }
      }
      if (!response.data.result) {
        return {
          value: null,
          error: new Error(`API returned empty response`),
        }
      }
      return {
        value: response.data.result,
        error: null,
      }
    } catch (e) {
      return {
        value: null,
        error: new Error('Network error, try again late'),
      }
    }
  }

  getPricesExtendedChart = async (
    filter: DealFilter
  ): Promise<IResultModel<GetListResponse<DealChartExtendedData>>> => {
    try {
      const params: DealsQueryContract = {
        ...filter,
      }
      const response = await this.connector.conn.get<
        IResultJSONModel<GetListResponse<DealChartExtendedData>>
      >(`/v1/deals/prices/extended`, { params })

      if (response.status !== 200) {
        return {
          value: null,
          error: new Error(`API error, status: ${response.status}`),
        }
      }
      if (!response.data.result) {
        return {
          value: null,
          error: new Error(`API returned empty response`),
        }
      }
      return {
        value: response.data.result,
        error: null,
      }
    } catch (e) {
      return {
        value: null,
        error: new Error('Network error, try again late'),
      }
    }
  }

  getRoomsPricesDynamics = async (
    filter: DealFilter
  ): Promise<IResultModel<GetListResponse<RoomsPriceChartData>>> => {
    try {
      const params: DealsQueryContract = {
        ...filter,
      }
      const response = await this.connector.conn.get<
        IResultJSONModel<GetListResponse<RoomsPriceChartData>>
      >(`/v1/deals/room-price-dynamics`, { params })

      if (response.status !== 200) {
        return {
          value: null,
          error: new Error(`API error, status: ${response.status}`),
        }
      }
      if (!response.data.result) {
        return {
          value: null,
          error: new Error(`API returned empty response`),
        }
      }
      return {
        value: response.data.result,
        error: null,
      }
    } catch (e) {
      return {
        value: null,
        error: new Error('Network error, try again late'),
      }
    }
  }

  getCountChart = async (
    filter: DealFilter,
    dataType: DealsChartDataType
  ): Promise<IResultModel<GetListResponse<DealChartData>>> => {
    try {
      const params: DealsQueryContract = {
        ...filter,
      }
      const response = await this.connector.conn.get<
        IResultJSONModel<GetListResponse<DealChartData>>
      >(`/v1/deals/count`, { params: { ...params, data_type: dataType } })

      if (response.status !== 200) {
        return {
          value: null,
          error: new Error(`API error, status: ${response.status}`),
        }
      }
      if (!response.data.result) {
        return {
          value: null,
          error: new Error(`API returned empty response`),
        }
      }
      return {
        value: response.data.result,
        error: null,
      }
    } catch (e) {
      return {
        value: null,
        error: new Error('Network error, try again late'),
      }
    }
  }

  getCountExtendedChart = async (
    filter: DealFilter
  ): Promise<IResultModel<GetListResponse<DealChartExtendedData>>> => {
    try {
      const params: DealsQueryContract = {
        ...filter,
      }
      const response = await this.connector.conn.get<
        IResultJSONModel<GetListResponse<DealChartExtendedData>>
      >(`/v1/deals/count/extended`, { params })

      if (response.status !== 200) {
        return {
          value: null,
          error: new Error(`API error, status: ${response.status}`),
        }
      }
      if (!response.data.result) {
        return {
          value: null,
          error: new Error(`API returned empty response`),
        }
      }
      return {
        value: response.data.result,
        error: null,
      }
    } catch (e) {
      return {
        value: null,
        error: new Error('Network error, try again late'),
      }
    }
  }

  getMonthScatterChart = async (
    filter: DealFilter
  ): Promise<IResultModel<GetListResponse<DealChartScatterData>>> => {
    try {
      const params: DealsQueryContract = {
        ...filter,
      }
      const response = await this.connector.conn.get<
        IResultJSONModel<GetListResponse<DealChartScatterData>>
      >(`/v1/deals/scatter/mounth`, { params })

      if (response.status !== 200) {
        return {
          value: null,
          error: new Error(`API error, status: ${response.status}`),
        }
      }
      if (!response.data.result) {
        return {
          value: null,
          error: new Error(`API returned empty response`),
        }
      }
      return {
        value: response.data.result,
        error: null,
      }
    } catch (e) {
      return {
        value: null,
        error: new Error('Network error, try again late'),
      }
    }
  }

  getPriceScatterChart = async (
    filter: DealFilter
  ): Promise<IResultModel<GetListResponse<DealChartScatterData>>> => {
    try {
      const params: DealsQueryContract = {
        ...filter,
      }
      const response = await this.connector.conn.get<
        IResultJSONModel<GetListResponse<DealChartScatterData>>
      >(`/v1/deals/scatter/price`, { params })

      if (response.status !== 200) {
        return {
          value: null,
          error: new Error(`API error, status: ${response.status}`),
        }
      }
      if (!response.data.result) {
        return {
          value: null,
          error: new Error(`API returned empty response`),
        }
      }
      return {
        value: response.data.result,
        error: null,
      }
    } catch (e) {
      return {
        value: null,
        error: new Error('Network error, try again late'),
      }
    }
  }

  syncDeals = async (query: QueryContract): Promise<IResultModel<null>> => {
    try {
      const params = {
        limit: query?.limit || 500,
      }
      const response = await this.connector.conn.get(`/v1/deals/synchronize`, {
        params,
      })

      if (response.status !== 204) {
        return {
          value: null,
          error: new Error(`API error, status: ${response.status}`),
        }
      }
      return {
        value: response.data.result,
        error: null,
      }
    } catch (e) {
      return {
        value: null,
        error: new Error('Network error, try again late'),
      }
    }
  }

  getDealsPieChart = async (
    filter: DealFilter,
    dataType: DealsChartDataType
  ): Promise<IResultModel<GetDealsPieChartResponse>> => {
    try {
      const params: DealsQueryContract = {
        ...filter,
      }
      const response = await this.connector.conn.get<
        IResultJSONModel<GetDealsPieChartResponse>
      >(`/v1/deals/count/by-types`, {
        params: { ...params, data_type: dataType },
      })

      if (response.status !== 200) {
        return {
          value: null,
          error: new Error(`API error, status: ${response.status}`),
        }
      }
      if (!response.data.result) {
        return {
          value: null,
          error: new Error(`API returned empty response`),
        }
      }
      return {
        value: response.data.result,
        error: null,
      }
    } catch (e) {
      return {
        value: null,
        error: new Error('Network error, try again late'),
      }
    }
  }
}
