import { AUTH_LOGIN, AUTH_PASSWORD } from '@/constants/auth'
import { RouterContext } from '@/models/router/Common'

export default function isAuth({ next }: RouterContext): void {
  const login = localStorage.getItem(AUTH_LOGIN)
  const password = localStorage.getItem(AUTH_PASSWORD)
  if (login && password) {
    return next()
  }
  return next({
    name: 'signIn',
  })
}
