import {
  GetListResponse,
  IConnector,
  IResultJSONModel,
  IResultModel,
  QueryContract,
} from '@/models/repository/Common'
import { ImportProcess } from '@/models/repository/ImportProcessContracts'
import { IImportProcessActions } from '../interfaces'

export default class ImportProcessActions implements IImportProcessActions {
  connector: IConnector
  constructor(connector: IConnector) {
    this.connector = connector
  }

  getList = async (
    query: QueryContract
  ): Promise<IResultModel<GetListResponse<ImportProcess>>> => {
    try {
      const params: QueryContract = {
        page: query.page ?? 1,
        limit: query.limit ?? 20,
        order_by: 'created_at',
        order_dir: 'desc',
      }
      const response = await this.connector.conn.get<
        IResultJSONModel<GetListResponse<ImportProcess>>
      >(`/v1/import`, { params })

      if (response.status !== 200) {
        return {
          value: null,
          error: new Error(`API error, status: ${response.status}`),
        }
      }
      if (!response.data.result) {
        return {
          value: null,
          error: new Error(`API returned empty response`),
        }
      }
      return {
        value: response.data.result,
        error: null,
      }
    } catch (e) {
      return {
        value: null,
        error: new Error('Network error, try again late'),
      }
    }
  }

  create = async (file: File): Promise<IResultModel<ImportProcess>> => {
    try {
      const formData = new FormData()
      formData.append('file', file)
      const response = await this.connector.conn.post<
        IResultJSONModel<ImportProcess>
      >(`/v1/import`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      if (response.status !== 200) {
        return {
          value: null,
          error: new Error(`API error, status: ${response.status}`),
        }
      }
      if (!response.data.result) {
        return {
          value: null,
          error: new Error(`API returned empty response`),
        }
      }
      return {
        value: response.data.result,
        error: null,
      }
    } catch (e) {
      return {
        value: null,
        error: new Error('Network error, try again late'),
      }
    }
  }
}
