import { SignInRequest } from '@/models/repository/AuthContracts'
import {
  IConnector,
  IResultJSONModel,
  IResultModel,
} from '@/models/repository/Common'
import { IAuthActions } from '../interfaces'

export default class AuthActions implements IAuthActions {
  connector: IConnector
  constructor(connector: IConnector) {
    this.connector = connector
  }

  signIn = async (data: SignInRequest): Promise<IResultModel<boolean>> => {
    try {
      const response = await this.connector.conn.post<
        IResultJSONModel<boolean>
      >(
        `/v1/auth/sign-in`,
        {},
        {
          auth: {
            username: data.login,
            password: data.password,
          },
        }
      )

      if (response.status !== 200) {
        return {
          value: null,
          error: new Error(`API error, status: ${response.status}`),
        }
      }
      if (!response.data.result) {
        return {
          value: null,
          error: new Error(`API returned empty response`),
        }
      }
      return {
        value: response.data.result,
        error: null,
      }
    } catch (e) {
      return {
        value: null,
        error: new Error('Network error, try again late'),
      }
    }
  }
}
