import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/styles/common.scss'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import Repository from './repository'
import { REPOSITORY } from './constants/index'
import ru from 'element-plus/es/locale/lang/ru'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import YmapPlugin from 'vue-yandex-maps'

const app = createApp(App)

app.use(store)
app.use(router)
app.use(ElementPlus, {
  locale: ru,
})

const settings = {
  apiKey: 'fe95027d-bdc8-45f7-83fa-0ae71a538367',
  lang: 'ru_RU',
  coordorder: 'latlong',
  enterprise: false,
  version: '2.1',
}

app.use(YmapPlugin, settings)

app.provide(REPOSITORY, new Repository(store))

app.mount('#app')

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
