import { ROLES } from '@/constants/auth'
import { getUserRole } from '@/helpers/role'
import { RouterContext } from '@/models/router/Common'

export default function isAdmin({ next }: RouterContext): void {
  const role = getUserRole()
  return role === ROLES.ADMIN
    ? next()
    : next({
        name: 'analitycs',
      })
}
