import { RouterContext, RouterMiddleware } from '@/models/router/Common'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import isAuth from './middleware/isAuth'
import isAdmin from './middleware/isAdmin'
import isGuest from './middleware/isGuest'
import middlewarePipeline from './middleware/middlewarePipeline'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/views/IndexView.vue'),
    meta: {
      middleware: [isAuth],
    },
    children: [
      {
        path: '',
        name: 'analitycs',
        component: () => import('@/views/AnalyticView.vue'),
        meta: {
          middleware: [isAuth],
        },
      },
      {
        path: 'deals',
        component: () => import('@/components/atoms/IndexAtoms.vue'),
        meta: {
          middleware: [isAuth],
        },
        children: [
          {
            path: '',
            name: 'DealsList',
            component: () => import('@/views/deals/DealsList.vue'),
            meta: {
              middleware: [isAuth],
            },
          },
          {
            path: ':id',
            name: 'DealsEdit',
            component: () => import('@/views/deals/DealsEdit.vue'),
            meta: {
              middleware: [isAuth],
            },
          },
        ],
      },
      {
        path: 'map-deals',
        component: () => import('@/components/atoms/IndexAtoms.vue'),
        meta: {
          middleware: [isAuth],
        },
        children: [
          {
            path: '',
            name: 'Map',
            component: () => import('@/views/MapDeals.vue'),
            meta: {
              middleware: [isAuth],
            },
          },
        ],
      },
      {
        path: 'purposes',
        component: () => import('@/components/atoms/IndexAtoms.vue'),
        meta: {
          middleware: [isAuth, isAdmin],
        },
        children: [
          {
            path: '',
            name: 'PurposesList',
            component: () => import('@/views/purposes/PurposesList.vue'),
            meta: {
              middleware: [isAuth, isAdmin],
            },
          },
          {
            path: ':id',
            name: 'PurposesEdit',
            component: () => import('@/views/purposes/PurposesEdit.vue'),
            meta: {
              middleware: [isAuth, isAdmin],
            },
          },
          {
            path: 'create',
            name: 'PurposesCreate',
            component: () => import('@/views/purposes/PurposesCreate.vue'),
            meta: {
              middleware: [isAuth, isAdmin],
            },
          },
        ],
      },
      {
        path: 'import',
        name: 'import',
        component: () => import('@/views/ImportView.vue'),
        meta: {
          middleware: [isAuth, isAdmin],
        },
      },
    ],
  },
  {
    path: '/sign-in',
    name: 'signIn',
    component: () => import('@/views/SignInView.vue'),
    meta: {
      middleware: [isGuest],
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const middleware: RouterMiddleware[] = to.meta
    .middleware as RouterMiddleware[]
  const context: RouterContext = {
    to,
    from,
    next,
  }
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  })
})

export default router
