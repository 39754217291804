import { AUTH_LOGIN, AUTH_PASSWORD } from '@/constants/auth'
import { IConnector } from '@/models/repository/Common'
import axios, { AxiosInstance } from 'axios'

export default class Connector implements IConnector {
  conn: AxiosInstance
  constructor(store: any) {
    this.conn = axios.create({
      baseURL: '/api',
    })

    this.conn.interceptors.request.use((config) => {
      const newConfig = { ...config }
      if (
        localStorage.getItem(AUTH_LOGIN) &&
        localStorage.getItem(AUTH_PASSWORD)
      ) {
        newConfig.auth = {
          username: localStorage.getItem(AUTH_LOGIN) ?? '',
          password: localStorage.getItem(AUTH_PASSWORD) ?? '',
        }
      }

      return newConfig
    })
  }
}
