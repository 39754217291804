import CoreService from './CoreService'
import { ICoreService } from './CoreService/interfaces'
import { IRepository } from './interfaces'

export default class Repository implements IRepository {
  constructor(store: any) {
    this.CS = new CoreService(store)
  }
  CS: ICoreService
}
