import {
  IConnector,
  IResultModel,
  TreeQueryContract,
  QueryContract,
} from '@/models/repository/Common'
import { IPurposeActions } from '../interfaces'
import { PurposeNode, Purpose } from '@/models/repository/Purpose'

export default class PurposeActions implements IPurposeActions {
  connector: IConnector
  constructor(connector: IConnector) {
    this.connector = connector
  }

  getTree = async (
    query?: QueryContract,
    options?: TreeQueryContract
  ): Promise<IResultModel<PurposeNode>> => {
    try {
      const params = {
        order_by: query?.order_by || 'order_field',
        order_dir: query?.order_dir || 'asc',
        ...options,
      }

      const response = await this.connector.conn.get(`/v1/purposes/tree`, {
        params,
      })

      if (response.status !== 200) {
        return {
          value: null,
          error: new Error(`API error, status: ${response.status}`),
        }
      }
      if (!response.data.result) {
        return {
          value: null,
          error: new Error(`API returned empty response`),
        }
      }
      return {
        value: response.data.result,
        error: null,
      }
    } catch (e) {
      return {
        value: null,
        error: new Error('Network error, try again late'),
      }
    }
  }

  remove = async (id: number): Promise<IResultModel<null>> => {
    try {
      const response = await this.connector.conn.delete(`/v1/purposes/${id}`)

      if (response.status !== 204) {
        return {
          value: null,
          error: new Error(`API error, status: ${response.status}`),
        }
      }

      return {
        value: null,
        error: null,
      }
    } catch (e) {
      return {
        value: null,
        error: new Error('Network error, try again late'),
      }
    }
  }

  create = async (data: Purpose): Promise<IResultModel<Purpose>> => {
    try {
      const response = await this.connector.conn.post(`/v1/purposes`, data)

      if (response.status !== 201) {
        return {
          value: null,
          error: new Error(`API error, status: ${response.status}`),
        }
      }

      return {
        value: response.data.result,
        error: null,
      }
    } catch (e) {
      return {
        value: null,
        error: new Error('Network error, try again late'),
      }
    }
  }

  update = async (data: Purpose[]): Promise<IResultModel<Purpose[]>> => {
    try {
      const response = await this.connector.conn.put(`/v1/purposes`, data)

      if (response.status !== 200) {
        return {
          value: null,
          error: new Error(`API error, status: ${response.status}`),
        }
      }

      return {
        value: response.data.result,
        error: null,
      }
    } catch (e) {
      return {
        value: null,
        error: new Error('Network error, try again late'),
      }
    }
  }

  updateOne = async (
    id: string,
    data: Purpose
  ): Promise<IResultModel<Purpose>> => {
    try {
      const response = await this.connector.conn.put(`/v1/purposes/${id}`, data)

      if (response.status !== 200) {
        return {
          value: null,
          error: new Error(`API error, status: ${response.status}`),
        }
      }

      return {
        value: response.data.result,
        error: null,
      }
    } catch (e) {
      return {
        value: null,
        error: new Error('Network error, try again late'),
      }
    }
  }

  getOneById = async (id: string): Promise<IResultModel<Purpose>> => {
    try {
      const response = await this.connector.conn.get(`/v1/purposes/${id}`)

      if (response.status === 200) {
        return {
          value: response.data.result,
          error: null,
        }
      } else {
        return {
          value: null,
          error: new Error('Network error, try again later'),
        }
      }
    } catch (e) {
      return {
        value: null,
        error: new Error('Network error, try again late'),
      }
    }
  }

  getList = async (): Promise<IResultModel<Purpose[]>> => {
    try {
      const response = await this.connector.conn.get(`/v1/purposes`)

      if (response.status === 200) {
        return {
          value: response.data.result,
          error: null,
        }
      } else {
        return {
          value: null,
          error: new Error('Network error, try again later'),
        }
      }
    } catch (e) {
      return {
        value: null,
        error: new Error('Network error, try again late'),
      }
    }
  }
}
